<template>
  <div class="page-right flex">
    <div class="menu">
      <yos-tree
        :treeData="treeData"
        :chose="onChose"
        :remove="onRemove"
      />
    </div>
    <div class="panel cell">
      <bread-crumbs :list="breadList" />
      <div class="input-wrapper">
        <yos-button class="yos-button-primary" @click.native="onAdd">新增子权限</yos-button>
        <yos-input
          v-if="!isTop"
          v-model.trim="right.sort"
          placeholder="请输入排序"
        />
        <yos-input
          v-model.trim="right.name"
          placeholder="请输入权限名称"
          :readonly="isTop"
        />
        <yos-input
          v-if="!isTop"
          v-model.trim="right.path"
          placeholder="请输入权限路径，以“/”开头"
        />
        <yos-input
          v-if="!isTop"
          v-model.trim="right.method"
          placeholder="请输入权限方法，“GET”、“POST”、“PUT”、“PATCH”、“DELETE”，不区分大小写"
        />
        <yos-input
          v-if="!isTop"
          v-model.trim="right.icon"
          placeholder="请输入菜单图标名称"
        />
        <yos-checkbox
          v-if="!isTop"
          label="菜单"
          v-model="right.type"
          :trueValue="1"
          :falseValue="0"
        />
        <yos-checkbox
          v-if="!isTop"
          label="禁用"
          v-model="right.status"
          :trueValue="0"
          :falseValue="1"
        />
      </div>
      <bottom-options
        :readonly="isTop"
        :saveText="this.right.id ? '更新' : '保存'"
        :deleteText="this.right.id >= 0 ? '删除' : ''"
        @save="onSave"
        @delete="onRemove(this.right)"
      />
    </div>
  </div>
</template>

<script>
import YosTree from '../components/other/yos-tree'
import BottomOptions from '../components/common/bottom-options'
import BreadCrumbs from '../components/common/bread-crumbs'
import {
  getRight,
  addRight,
  updateRight,
  deleteRight
} from '../data'
import { useServer, useToast, useDialog } from '../hook'
import { reactive, toRefs, computed } from 'vue'
export default {
  components: {
    YosTree,
    BottomOptions,
    BreadCrumbs
  },
  setup() {
    const toast = useToast()
    const dialog = useDialog()
    const state = reactive({
      right: {
        sort: 0,
        parentId: '',
        id: '',
        type: 0,
        name: '全部权限',
        path: '',
        method: '',
        status: 1
      },
      breadList: [{
        name: '全部权限'
      }],
      treeData: [],
      rights: []
    })
    const all = {
      id: -1,
      name: '全部权限',
      show: true
    }

    const isTop = computed(() => state.right.id === -1)

    const getTreeData = (data) => {
      let newData = []
      let childrenData = []

      data.forEach(item => {
        if (!item.parentId) {
          newData.push(item)
        } else {
          childrenData.push(item)
        }
      })

      function formatData (data, children) {
        data.forEach(item => {
          let next = []
          let other = []

          children.forEach(entry => {
            if (entry.parentId === item.id) {
              next.push(entry)
            } else {
              other.push(entry)
            }
          })

          if (next.length > 0) {
            item.children = next
            formatData(item.children, other)
          }
        })
      }

      formatData(newData, childrenData)

      return newData
    }

    const getFlatData = (data) => {
      const newData = []

      function formatData (data) {
        data.forEach(item => {
          newData.push(item)
          if (Array.isArray(item.children)) {
            formatData(item.children)
          }
        })
      }

      formatData(data || [])

      return newData
    }

    const setRightParams = useServer(getRight, ({ rights }) => {
      state.rights = rights
      state.treeData = getTreeData([
        ...rights,
        all
      ])
    })

    const onChose = (data) => {
      const list = [{name: data.name, id: data.id}]
      const findParent = (parentId) => {
        const parent = state.rights.find(item => item.id === parentId)

        if (parent) {
          list.unshift({name: parent.name, id: parent.id})
          findParent(parent.parentId)
        }
      }

      findParent(data.parentId)
      state.breadList = list
      state.right = {...data, method: (data.method || '').toUpperCase()}
    }

    const onAdd = () => {
      state.right = {
        parentId: state.right.id,
        sort: 0,
        id: '',
        name: '',
        path: '',
        method: '',
        type: 0,
        status: 1
      }
    }

    const setDeleteRightParams = useServer(deleteRight, ({ msg }, { id }) => {
      const rights = getFlatData(state.treeData)
      state.treeData = getTreeData(rights.filter(item => item.id !== id))
      onChose(all)
      toast(msg)
    })

    const setUpdateRightParams = useServer(updateRight, ({ msg }, right) => {
      const rights = getFlatData(state.treeData)
      state.treeData = getTreeData(rights.map(item => {
        if (item.id === right.id) return right
        return item
      }))
      toast(msg)
    })

    const setAddRightParams = useServer(addRight, ({ id, msg }, right) => {
      const rights = getFlatData(state.treeData)
      rights.push({ ...right, id })
      state.treeData = getTreeData(rights)
      toast(msg)
    })

    const onRemove = ({ id, name }) => {
      dialog({
        title: '删除权限',
        content: `您确定要删除权限 ${name}？/n删除后，权限不可恢复，使用该权限的页面将无法访问`,
        onOk: () => {
          setDeleteRightParams({ id })
        }
      })
    }

    const onSave = () => {
      if (state.right.id) {
        setUpdateRightParams(state.right)
      } else {
        setAddRightParams(state.right)
      }
    }

    setRightParams()

    return {
      isTop,
      ...toRefs(state),
      onChose,
      onAdd,
      onRemove,
      onSave
    }
  }
}
</script>

<style lang="postcss">
.page-right {
  padding: 80px 50px 0 50px;

  .menu {
    padding: 30px 0;
  }

  .input-wrapper {
    padding: 20px 0;

    .yos-input {
      display: block;
      margin-top: 30px;
      width: 100%;

      .yos-input-inner {
        width: 100%;
      }
    }

    .yos-checkbox {
      display: block;
      margin: 20px 0;
    }
  }
}
</style>
