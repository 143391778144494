<template>
  <div class="yos-tree">
    <div
      class="item"
      v-for="(item, idx) in treeData"
      :key="idx"
    >
      <span>
        <i
          :class="addClass('icon-pull-down', [item.show, 'show'], [!item.children, 'hidden'])"
          @click="onShow(item.id)"
        />
        <span class="entry" @click="onChose(item)">
          <yos-checkbox
            v-if="Array.isArray(flatData) && flatData.length > 0"
            v-model="checkedIds[item.id]"
            :disabled="disabled"
            @change="onChange(item, checkedIds && checkedIds[item.id], parent)"
          />
          {{item.name}}
          <i class="remove" v-if="removeAbled && item.id >= 0" @click="onRemove(item)">删除</i>
        </span>
      </span>
      <yos-tree
        v-if="item.children && item.show"
        :treeData="item.children"
        :flatData="flatData"
        :parent="item"
        :checkedIds="checkedIds"
        :chose="chose"
        :removeAbled="removeAbled"
        :remove="remove"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import YosCheckbox from '../form/yos-checkbox'
import { reactive, toRefs } from 'vue'
import { addClass } from '../../util'

export default {
  components: {
    YosCheckbox
  },
  props: {
    treeData: Array,
    flatData: Array,
    parent: Object,
    checkedIds: Object,
    chose: Function,
    removeAbled: false,
    remove: Function,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const state = reactive({
      treeData: props.treeData,
      disabled: props.disabled,
      checkedIds: props.checkedIds,
      chose: props.chose,
      remove: props.remove,
      flatData: props.flatData
    })

    const onShow = (id) => {
      const newData = [...state.treeData]

      const changeItem = (data) => {
        data.forEach(item => {
          if (item.id === id) {
            item.show = !item.show
            return
          }

          if (item.children) {
            changeItem(item.children)
          }
        })
      }

      changeItem(newData)
      state.treeData = newData
    }

    const onRemove = (current) => {
      state.remove(current)
    }

    const onChange = (current, checked, parent) => {
      if (state.disabled) return

      const nextChange = (current, checked, parent) => {
        let prevParent, next

        if (parent) {
          if (parent.children) {
            if (checked) {
              state.checkedIds[parent.id] = parent.children.filter(item => state.checkedIds[item.id]).length === parent.children.length
            } else {
              state.checkedIds[parent.id] = false
            }
          }

          prevParent = state.flatData.find(item => {
            return item.id === parent.parent_id
          })

          if (prevParent) {
            next = {...parent}
            delete next.children
            nextChange(next, checked, prevParent)
          }
        }

        if (current.children) {
          current.children.forEach(item => {
            state.checkedIds[item.id] = checked

            if (item.children) {
              nextChange(item, checked, parent)
            }
          })
        }
      }

      nextChange(current, checked, parent || state.treeData)
    }

    const onChose = (current) => {
      if (state.chose) {
        state.chose(current)
      }
    }

    return {
      ...toRefs(state),
      addClass,
      onShow,
      onRemove,
      onChange,
      onChose
    }
  }
}
</script>

<style lang="postcss">
.yos-tree {
  .item {
    padding: 0 20px;
    line-height: 2;

    .icon-pull-down {
      vertical-align: middle;
      user-select: none;
      transform: rotate(-90deg);

      &.show {
        transform: rotate(0);
      }

      &.hidden {
        visibility: hidden;
      }
    }

    .entry {
      cursor: pointer;

      .remove {
        display: none;
        font-size: 12px;
        font-style: normal;
      }

      &:hover {
        .remove {
          display: inline;
        }
      }
    }
  }
}
</style>
